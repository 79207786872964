module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-10858915-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nicola Della Marina","short_name":"Nicola Della Marina","start_url":"/","background_color":"#f1faee","theme_color":"#457b9d","icon":"static/icon.png","icon_options":{"purpose":"any maskable"},"display":"standalone","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"07b3323d2c0eb75a6b8366ce315a5ae6"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
